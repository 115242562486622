html,
body {
  overflow: hidden;
}

html,
body,
#root {
  min-height: 0;
  min-width: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-basis: 100%;
  height: 100%;
  /* background-color: red; */
  background-color: #F9F3EB !important;
}

html {
  font-family: 'Nunito';
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Nunito';
}
#root {
  font-family: 'Nunito';
}